@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700;900&display=swap");

$theme-colors: (
  "danger": #dc3545,
  "blue": #2b566f,
  "theme-light": #f4f4f4,
  "form-header": #25516a,
  "form-text": #90a4b3,
);

html {
  scroll-behavior: smooth;
}

#wapper {
  position: relative;
  display: block;
  width: 100%;
  min-height: 100%;
  overflow: hidden;
}
#mainCntr {
  width: 100%;
}
#headerCntr {
  padding-top: 13px;
  padding-bottom: 6px;
  background-color: #fff;
  position: relative;
  width: 100%;
}

/* ### general classes ### */
body {
  font-family: "Roboto", sans-serif;
  font-size: 17px;
  color: #103555;
  line-height: 1.5;
  background: #f4f4f4;
}

input,
select,
textarea {
  font-family: "Roboto", sans-serif;
  font-size: 17px;
  border-radius: 5px;
}
img {
  display: block;
  border: 0;
  max-width: 100%;
}
ul,
li {
  list-style: none;
}
:focus {
  outline: none;
}
a {
  text-decoration: none;
  display: inline-block;
}
.footer-underline {
  text-decoration: underline;
}
h1 {
  font-size: 43px;
  font-weight: 900;
  line-height: 1.15;
}
h2 {
  font-size: 43px;
  font-weight: 700;
  line-height: 1.15;
}
h3 {
  font-size: 27px;
  font-weight: 700;
  line-height: 1.15;
  @media (max-width: 500px) {
    font-size: 16px !important;
  }
}
p {
  font-size: 23px;
  font-weight: 100;
  line-height: 1.15;
}
.para {
  font-size: 20px;
  font-weight: 400;
  line-height: 1.15;
}
.subtext {
  color: #1e4a65;
  font-size: 20px;
  font-weight: 400;
  line-height: 1.15;
}
.privacy-text {
  font-size: 15px;
  font-weight: 400;
  line-height: 1.15;
}
.btn {
  padding: 12px 38px; //   padding: 12px 50px;
  border: 4px solid #fff;
  font-size: 25px !important; // font-size: 51px;
  color: #fff;
  font-weight: 700;
  border-radius: 50px;
  transition: all 0.3s;
  box-shadow: 0 0 30px 0 rgba(95, 181, 146, 0.4);

  @media (max-width: 1199px) {
    // font-size: 45px;
    font-size: 30px !important;
  }
  @media (max-width: 991px) {
    font-size: 30px !important;
  }

  @media (max-width: 767px) {
    width: 100%;
    font-size: 30px !important;
    padding: 10px 15px;
    text-align: center;
  }
  @media (max-width: 500px) {
    font-size: 23px !important;
  }
  @media (max-width: 455px) {
    font-size: 21px !important;
  }
  @media (max-width: 420px) {
    font-size: 20px !important;
  }
  @media (max-width: 390px) {
    font-size: 17px !important;
  }
  @media (max-width: 350px) {
    font-size: 15px !important;
  }
}
.btn-primary {
  background: linear-gradient(
    135deg,
    rgba(95, 181, 147, 1) 0%,
    rgba(64, 122, 98, 1) 100%
  );
}
.btn-primary:hover {
  background: linear-gradient(
    135deg,
    rgba(64, 122, 98, 1) 0%,
    rgba(95, 181, 147, 1) 100%
  );
  border: 4px solid #fff;
}

.text-md-end {
  @media (min-width: 768px) {
    text-align: right !important;
  }
}
.d-md-block {
  @media (min-width: 768px) {
    display: block !important;
  }
}
.container {
  @media (min-width: 1400px) {
    max-width: 960px;
  }
}
.fijlogo {
  @media (max-width: 2700px) {
    margin-top: 10px !important;
  }
  @media (max-width: 2400px) {
    margin-bottom: 50px !important;
  }
  @media (max-width: 1800px) {
    margin-bottom: 80px !important;
  }
  @media (max-width: 1200px) {
    margin-bottom: 100px !important;
  }
}
/* ### end general classes ### */

/* ### banner box classes ### */
.banner-box {
  padding-top: 23px; //   padding-top: 50px;
  position: relative;
  min-height: 714px; //   min-height: 755px;

  &__content {
    position: relative;
    z-index: 1;
    max-width: 885px;
    margin: 0 auto;
    color: #fff;
    &-heading {
      line-height: 0.98;
      margin-bottom: 7px;

      @media (max-width: 991px) {
        font-size: 36px;
      }
      &_sub-heading {
        font-size: 38px;
        font-weight: 700;
        @media (max-width: 991px) {
          font-size: 30px;
        }
      }
    }
    &_sub-title {
      display: block;
      font-size: 21px;
      color: #fff;
      @media (max-width: 991px) {
        font-size: 17px;
      }
    }
  }
  &_image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    &__bottom-layer {
      height: 755px;
      width: 100%;
      object-position: center center;
      object-fit: cover;
    }
  }
}
.banner-box::after {
  background: url("../img/banner-bottom-layer.png") no-repeat;
  height: 518px;
  width: 100%;
  background-size: cover;
  position: absolute;
  bottom: -310px;
  left: 0;
  content: "";
}
.banner-box::before {
  background-image: url("../img/board-icon.png");
  background-repeat: no-repeat;
  height: 67px;
  width: 146px;
  content: "";
  position: absolute;
  top: -58px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 1;
  @media (max-width: 767.98px) {
    display: none !important;
  }
}
/* ### end banner classes ### */
/* ### Form Box ### */
.hero_section {
  background-image: url("../img/bg-banner-full.png");
  background-size: cover;
  // background-repeat: no-repeat;
}
.form-box {
  // position: relative;
  // z-index: 2;
  // margin-top: -540px;
  @media all and (min-width: 768px) and (max-width: 980px) {
    margin-top: -480px;
  }
  @media (max-width: 767px) {
    margin-top: -388px;
  }
  //   @media (max-width: 767px) {
  //     margin-top: -430px;
  //   }
  &__bg {
    padding: 15px;
    max-width: 500px; // max-width: 601px; // max-width: 686px; // max-width: 732px;
    box-shadow: 0 0 8px 0 rgba(19, 54, 80, 0.5);
    border-radius: 35px;
    // margin: 0 auto;
    background: linear-gradient(
      135deg,
      rgba(161, 110, 51, 1) 0%,
      rgba(214, 175, 86, 1) 50%,
      rgba(161, 110, 51, 1) 100%
    );
    @media (max-width: 1199px) {
      max-width: 660px;
    }
    @media (max-width: 991px) {
      max-width: 500px;
    }
    @media only screen and (max-width: 767px) {
      max-width: 100%;
    }
    @media (max-width: 500px) {
      padding: 10px !important;

      border-radius: 27px !important;
    }
    &__box {
      padding: 40px 38px 45px 33px;
      background-color: #fff;
      border-radius: 20px;
      @media (max-width: 767px) {
        padding: 35px 25px 30px;
      }
      h3 {
        color: #25516a;
      }
      h4 {
        color: #25516a;
        @media (max-width: 500px) {
          font-size: 16px !important;
        }
      }
      h5 {
        color: #2b566f;
        margin-bottom: 24px;
        // font-size: 35px;
        font-size: 20px;

        span {
          color: #e95249;
        }
        @media (max-width: 991px) {
          font-size: 30px;
        }
      }
      &__row {
        margin: 0 -7px;
        &-gender {
          font-size: 20px;
          // padding-left: 40px;
          padding-left: 13px;
          color: #19405d;
          // font-size: 19px;

          display: block;
          cursor: pointer;
          line-height: 1.25;
        }
        .form-button {
          background: linear-gradient(
            135deg,
            rgba(95, 181, 147, 1) 0%,
            rgba(64, 122, 98, 1) 100%
          );
          @media (max-width: 330px) {
            font-size: 13px !important;
          }
        }
        .form-group {
          margin-bottom: 2px; // margin-bottom: 11px;
          padding: 0 7px;
          position: relative;
          z-index: 2;
          label {
            color: #90a4b3;
          }
          .form-control {
            // padding: 10px 15px;
            padding: 5px 10px;
            font-family: "Roboto", sans-serif;
            font-size: 20px;
            // color: #4aa2c7;
            color: #90a4b3;
            background-color: #f4f4f4;
            height: 40px; // height: 55px;
            // border: 2px solid #4aa2c7;
            border: 2px solid #90a4b3;
            border-radius: 5px;
            width: 100%;
            -webkit-appearance: none;
            background-color: #fff;
            @media (max-width: 991px) {
              font-size: 17px;
            }
            @media (max-width: 500px) {
              font-size: 12px !important;
              border: 1px solid #90a4b3 !important;
            }
          }
          .form-control:focus {
            outline: unset;
            box-shadow: none;
          }
          label {
            span {
              padding-left: 40px;
              color: #25516a;
              font-size: 19px;
              display: block;
              cursor: pointer;
              line-height: 1.25;
              @media (max-width: 767px) {
                font-size: 15px;
              }
              @media (max-width: 991px) {
                font-size: 16px;
              }
            }
          }
          Input[type="text"] {
            background-color: #f4f4f4;
          }
          Input[type="email"] {
            background-color: #f4f4f4;
          }
          Input::placeholder {
            color: #90a4b3;
          }
          Input[type="checkbox"] {
            position: absolute;
            background-color: #f4f4f4;
            z-index: 1;
            top: 0;
            left: 20px;
            display: flex;
            align-items: center;
            justify-content: center;
            width: 15px;
            height: 15px;
            font-size: 10px;
            color: #fff;
            border-radius: 50%;
            border: 1px solid #90a4b3;
            -webkit-appearance: none;
            cursor: pointer;
            @media (max-width: 500px) {
              height: 11px !important;
              width: 11px !important;
            }
          }
          Input[type="checkbox"]:checked::before {
            height: 10px;
            width: 10px;
            background-color: #224e67;
            border-radius: 50%;
            content: "";
            @media (max-width: 500px) {
              height: 6px !important;
              width: 6px !important;
            }
          }
          Input[type="radio"] {
            margin-top: 3px;
            position: absolute;
            background-color: #f4f4f4;
            z-index: 1;
            top: 0;
            left: 0;
            display: flex;
            align-items: center;
            justify-content: center;
            width: 15px;
            height: 15px;
            font-size: 14px;
            color: #fff;
            border-radius: 50%;
            border: 1px solid #90a4b3;
            -webkit-appearance: none;
            cursor: pointer;
            @media (max-width: 500px) {
              height: 11px !important;
              width: 11px !important;
              margin-top: 1px;
            }
          }
          Input[type="radio"]:checked::before {
            height: 10px;
            width: 10px;
            background-color: #90a4b3;
            border-radius: 50%;
            content: "";
            @media (max-width: 500px) {
              height: 6px !important;
              width: 6px !important;
            }
          }
          .custom-select,
          .custom-before,
          .form-control {
            padding: 10px 15px;
            font-family: "Roboto", sans-serif;
            font-size: 16px;
            color: #90a4b3;
            height: 55px;
            border: 2px solid #90a4b3;
            border-radius: 5px;
            width: 100%;
            -webkit-appearance: none;
            background-color: #f4f4f4 !important;
            @media (max-width: 500px) {
              font-size: 12px !important;
              border: 1px solid #90a4b3 !important;
              height: 37px !important;
            }
            @media (max-width: 380px) {
              font-size: 10px !important;
            }
          }
          .custom-select {
            background: #fff url("../img/arrows.png") no-repeat right 15px top
              19px;
            cursor: pointer;
            background-color: #f4f4f4;
            margin-top: 10px;
            font-weight: 700 !important;
            color: #224e67 !important;
            @media (max-width: 500px) {
              font-size: 12px !important;
              border: 1px solid #90a4b3 !important;
              background: #fff url("../img/arrows.png") no-repeat right 15px top
                12px;
              margin-top: 0px !important;
            }

            @media (max-width: 380px) {
              background: #fff url("../img/arrows.png") no-repeat right 8px top
                12px;
              font-size: 10px !important;
            }
            @media (max-width: 340px) {
              background: #fff url("../img/arrows.png") no-repeat right 5px top
                12px;
              font-size: 9px !important;
            }
          }
          .custom-before {
            background: #fff url("../img/arrows.png") no-repeat right 15px top
              19px;
            cursor: pointer;
            background-color: #f4f4f4;
            margin-top: 10px;
            font-weight: 400 !important;
            color: #90a4b3 !important;
            @media (max-width: 500px) {
              font-size: 12px !important;
              margin-top: 0px;
              border: 1px solid #90a4b3 !important;
              background: #fff url("../img/arrows.png") no-repeat right 15px top
                12px;
            }
            @media (max-width: 380px) {
              background: #fff url("../img/arrows.png") no-repeat right 8px top
                12px;
              font-size: 10px !important;
            }
            @media (max-width: 340px) {
              background: #fff url("../img/arrows.png") no-repeat right 5px top
                12px;
              font-size: 9px !important;
            }
          }
          .options {
            font-weight: 700 !important;
            color: #224e67 !important;
          }
        }
        &-check {
          margin-top: 24px;
          margin-bottom: 37px;
        }
        span {
          padding-left: 40px;
          color: #385a76;
          font-size: 16px;
          display: block;
          cursor: pointer;
          line-height: 1.25;
          a {
            color: #25516a;
            text-decoration: underline;
          }
          @media (max-width: 450px) {
            padding-left: 0px;
            font-size: 12px;
          }
        }
      }
    }
  }
}
.top-radio-labels {
  font-size: 18px !important;
  font-weight: 400 !important;
  color: #3b5d76 !important;
  @media (max-width: 500px) {
    font-size: 12px !important;
  }
}
.top-radio-labels-after {
  font-size: 18px !important;
  font-weight: 600 !important;
  color: #3b5d76 !important;
  @media (max-width: 500px) {
    font-size: 12px !important;
  }
}
.top-labels {
  color: #3b5d76 !important;
  @media (max-width: 500px) {
    font-size: 12px !important;
  }
}
.top-filled {
  font-weight: 600 !important;
  color: #224e67 !important;
}
.top-filled::placeholder {
  font-weight: 400 !important;
  color: #90a4b3 !important;
}
.top-filled::placeholder::after {
  font-weight: 700 !important;
  color: #224e67 !important;
}
.top-filled[type="radio"]:checked::before {
  background-color: #224e67 !important ;
}
.form-check {
  position: relative;
  display: block;
  padding-left: 0.25rem;
}
.step-top {
  @media (max-width: 2826px) {
    position: relative !important;
    top: 35% !important;
  }
  @media (max-width: 2700px) {
    top: 40% !important;
  }
}
.girl-shake {
  // margin-top: 27%;
  // margin-left: 25%;
  @media (max-width: 2826px) {
    margin-top: -3% !important;
    margin-left: -40%;
    height: 740px !important;
    max-width: 170% !important;
  }
  @media (max-width: 2720px) {
    margin-top: 0% !important;
    height: 690px !important;
  }
  @media (max-width: 2670px) {
    margin-top: 0% !important;
    height: 680px !important;
  }
  @media (max-width: 2550px) {
    margin-top: 0% !important;
    height: 660px !important;
  }
  @media (max-width: 2490px) {
    margin-top: -0% !important;
    height: 640px !important;
  }
  @media (max-width: 2000px) {
    margin-top: -4% !important;
    height: 650px !important;
  }
  @media (max-width: 1400px) {
    margin-top: 0% !important;
    margin-left: -20%;
  }
  @media (max-width: 1399px) {
    // margin-top: 20px;
    margin-top: -0% !important;
    margin-left: -10%;
    height: 620px !important;
  }
  @media (max-width: 1280px) {
    margin-top: -0% !important;
    margin-left: -7% !important;
    height: 620px !important;
  }
  @media (max-width: 1199px) {
    margin-top: -3% !important;
    margin-left: -25% !important;
    height: 620px !important;
  }
}
.girl-img {
  position: absolute;
  top: -20% !important;
  left: 20% !important;
  z-index: 1;

  //   @media (max-width: 1400px) {
  //     width: 400px;
  //     top: -100px;
  //     left: -170px;
  //   }
  //   @media (max-width: 1400px) {
  //     width: 291px;
  //     top: -88px;
  //     left: -26px;
  //   }

  @media (max-width: 1400px) {
    width: 328px;
    top: -80px;
    left: 7px;
  }

  @media (max-width: 1199px) {
    width: 300px;
    top: -50px;
    left: -95px;
  }
}
.boy-img {
  position: absolute;
  top: -227px;
  right: -337px;
  z-index: 1;
  @media (max-width: 1400px) {
    width: 314px;
    top: 111px;
    right: 9px;
  }
  //   @media (max-width: 1400px) {
  //     width: 400px;
  //     top: -45px;
  //     right: -180px;
  //   }
  @media only screen and (max-width: 1199px) {
    width: 300px;
    top: 0;
    right: -100px;
  }
  @media (max-width: 991px) {
    right: -107px;
    bottom: -45px;
    top: auto;
  }
}
/* ### end Form Box  ### */
/* ### Step Box ### */
.stepbox {
  padding-top: 56px;
  margin-bottom: 70px;
  position: relative;
  z-index: 1;
  h3 {
    color: #e95249;
    font-size: 23px;
    font-weight: 700;
    line-height: 1.15;
  }
  &__title {
    // text-transform: uppercase;
    font-size: 25px;
    font-weight: 900;
    color: #fff;
    background: url("../img/cloud-img.png") no-repeat;
    height: 202px;
    width: 336px;
    margin: 0 auto;
    line-height: 1.25;
    letter-spacing: 0.4px;
    @media (max-width: 384px) {
      background: url("../img/cloud-small\ \(1\).png") no-repeat;
      margin-right: 70px;
    }
    @media (max-width: 2826px) {
      margin: -11% auto;
    }
    @media (max-width: 2700px) {
      margin: -5% auto;
    }
    &-space {
      // position: relative;
      // left:-7%;
      span {
        font-weight: 300;
        display: block;
        font-size: 20px;
        @media (max-width: 384px) {
          // margin-right: 70px;
        }
      }
    }
    &-step {
      @media (max-width: 385px) {
        position: relative;
        left: -8%;
        top: -10px;
      }

      span {
        font-weight: 300;
        display: block;
        font-size: 20px;
        @media (max-width: 384px) {
          // margin-right: 70px;
        }
      }
    }
  }
  &__box {
    max-width: 760px;
    margin: 0 auto;
    &-content {
      h3 {
        color: #e95249;
        font-size: 27px;
        font-weight: 700;
        line-height: 1.15;
      }
      p {
        line-height: 1.7;
      }
      p > a > u {
        font-weight: 700;
        text-decoration: underline;
        color: #103555;
      }
    }
    .join {
      padding-top: 80px;
    }
  }
  &__join {
    padding-top: 80px;
    @media only screen and (max-width: 767px) {
      padding-top: 40px;
    }
    &-btn {
      padding: 12px 50px;
      border: 4px solid #fff;
      font-size: 51px;
      color: #fff;
      font-weight: 700;
      border-radius: 50px;
      transition: all 0.3s;
      // box-shadow: 0 0 30px 0 rgb(95 181 146 / 40%);
      @media (max-width: 767.98px) {
        width: 100%;
        font-size: 18px;
        padding: 10px 15px;
        text-align: center;
      }
      @media only screen and (max-width: 1199px) {
        font-size: 45px;
      }
      @media only screen and (max-width: 991px) {
        font-size: 30px;
      }
      @media only screen and (max-width: 340px) {
        font-size: 20px;
      }
    }
  }
  /* reusable classes*/
  // .left-arrow,
  // .right-arrow {
  // 	position: absolute;
  // 	top: 50%;
  // 	transform: translateY(-50%);
  // 	z-index: -1;
  // }
  // .left-arrow {
  // 	left: 0;
  // }
  // .right-arrow {
  // 	right: 0;
  // }
  // .image.first img,
  // .image.third img {
  // 	margin-left: auto;
  // }
  // .join {
  // 	padding-top: 80px;
  // }

  /* end reuseable classes*/

  .left-arrow > img,
  .right-arrow > img {
    display: block;
    border: 0;
    max-width: 100%;
    @media only screen and (max-width: 767px) {
      width: 49px;
      margin: 0 auto;
    }
  }
}
/* ### end Step Box ### */
/* ### Privacy Box ### */
.privacy-box {
  background: linear-gradient(
    135deg,
    rgba(43, 86, 111, 1) 0%,
    rgba(16, 53, 85, 1) 100%
  );
  position: relative;
  padding-bottom: 14px;
  .content {
    padding-top: 125px;
    @media (max-width: 767.98px) {
      padding-top: 50px;
    }
  }
  p {
    color: #fff;
    line-height: 1.8;
    max-width: 455px;
  }
  a {
    color: #fff;
    line-height: 1.8;
  }
  image {
    position: relative;
    z-index: 1;
    margin-top: -28px;
    img {
      @media only screen and (max-width: 767px) {
        margin: 0 auto;
      }
    }
  }
}
.privacy-box::before {
  background-color: #f4f4f4;
  width: 100%;
  height: 104px;
  content: "";
  position: absolute;
  top: -54px;
  left: 0;
  transform: skewY(-2deg);
}

/* ### end Privacy Box ### */

/* ### Regex ### */
.was-validated .form-control:invalid:focus,
.form-control.is-invalid:focus {
  //   border: none !important;
  border-color: #ced4da;
  background-image: none !important;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 254, 0.25);
  //   box-shadow: none;
}
.form-control:required:is-invalid {
  //   border: none !important;
  background-image: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 width=%2712%27 height=%2712%27 fill=%27none%27 stroke=%27%23dc3545%27 viewBox=%270 0 12 12%27%3e%3ccircle cx=%276%27 cy=%276%27 r=%274.5%27/%3e%3cpath stroke-linejoin=%27round%27 d=%27M5.8 3.6h.4L6 6.5z%27/%3e%3ccircle cx=%276%27 cy=%278.2%27 r=%27.6%27 fill=%27%23dc3545%27 stroke=%27none%27/%3e%3c/svg%3e") !important;
  background-repeat: no-repeat;
  box-shadow: #808080 !important;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}

/* ### end Regex ### */
